<template>
  <v-container :elevation="0" fluid>
    <div class="d-flex mb-2" style="gap: 6px">
      <v-btn small color="primary" @click.stop="getContactos">
        <v-icon left>mdi-refresh</v-icon>
        recargar
      </v-btn>
      <v-btn small color="primary" @click.stop="detailsId = true">
        <v-icon left>mdi-plus</v-icon>
        Nuevo contacto
      </v-btn>
    </div>

    <v-data-table
      :headers="contactosHeaders"
      :items="contactos"
      :loading="loading"
      :single-select="false"
      item-key="idContacto"
      checkbox-color="secondary"
      :calculate-widths="true"
      class="fixed-checkbox"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [25, 50, -1],
      }"
    >
      <template v-slot:body.prepend="{ headers }">
        <table-filters
          :headers="headers"
          :items="contactos"
          v-model="inlineFilters"
        ></table-filters>
      </template>

      <template v-slot:item.nombreTipo="{ item }">
        <StatusChip :value="item.nombreTipo" />
        <v-icon v-if="item.respondida == 0" right small color="error">mdi-exclamation</v-icon>
      </template>

      <template v-slot:item.fechaContacto="{ item }">
        <span v-text="parseDate(item.fechaContacto, false, true, true)"></span>
      </template>

      <template v-slot:item.resumen="{ item }">
        <div class="d-flex align-center">
          <span
            class="d-inline-block mr-1 text-truncate"
            style="max-width: 600px"
            v-text="item.resumen"
          ></span>
          <v-btn
            @click.stop="detailsId = item.idContacto"
            small
            icon
            color="secondary"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.respondida="{ item }">
        <v-icon
          v-if="item.respondida == null || item.respondida == undefined"
          color=""
          >mdi-minus</v-icon
        >
        <v-icon v-else-if="item.respondida" color="success">mdi-phone</v-icon>
        <v-icon v-else-if="!item.respondida" color="error"
          >mdi-phone-off</v-icon
        >
      </template>

      <template v-slot:item.acciones="{ item }">
        <div class="d-flex" style="gap: 6px">
          <v-btn
            @click.stop="detailsId = item.idContacto"
            small
            outlined
            rounded
            color="secondary"
          >
            <v-icon left>mdi-eye</v-icon>
            Detalles
          </v-btn>
          <v-btn
            @click.stop="eliminarContacto(item.idContacto)"
            small
            outlined
            rounded
            color="error"
          >
            <v-icon left>mdi-delete</v-icon>
            Eliminar
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog
      :value="detailsId"
      @input="(v) => (v === false ? (detailsId = null) : null)"
      max-width="1100px"
    >
      <DetallesContacto v-if="detailsId != null && detailsId !== true" :contacto="contactos.find((c) => c.idContacto == detailsId)" />
      <NuevoContacto
        v-if="detailsId === true"
        :contacto="contactos.find((c) => c.idContacto == detailsId)"
        :idEstudio="idEstudio"
        :key="detailsId"
        @reload="getContactos"
        @close="detailsId = null"
      />
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { parseDate, decode64, perColumnFilter } from "@/utils/index.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
    NuevoContacto: () => import("./NuevoContacto.vue"),
    DetallesContacto: () => import("./DetallesContacto.vue"),
  },
  props: {
    idEstudio: String | Number,
  },
  computed: {
    nuevaHora() {
      if (!this.nuevaLlamada.hora || !this.nuevaLlamada.fecha) return;

      const [h, m] = this.nuevaLlamada.hora.split(":");
      let date = new Date(this.nuevaLlamada.fecha);
      date.setHours(h, m);
      return date.getTime();
    },
  },
  data() {
    return {
      loading: false,
      nuevaLlamada: {},
      max: new Date().toISOString().slice(0, 10),
      inlineFilters: {},
      contactos: [],
      contactosHeaders: [
        { text: "Tipo contacto", value: "nombreTipo", dataType: "select" },
        { text: "Fecha contacto", value: "fechaContacto", dataType: "date" },
        { text: "Resumen", value: "resumen" },
        { text: "Hecho por", value: "nombre" },
        {
          text: "Respondida",
          value: "respondida",
          dataType: "bool",
          align: "center",
        },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
          filterable: false,
        },
      ].map((header) => {
        return {
          class: "text-no-wrap sticky-header",
          cellClass: "pa-2 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        };
      }),
      detailsId: null,
    };
  },
  methods: {
    parseDate,
    decode64,
    async getContactos() {
      this.loading = true;
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/contactos_estudio/${this.idEstudio}`,
        method: "GET",
      });
      this.contactos = data;
      this.loading = false;
    },
    async eliminarContacto(idContacto) {
      if (
        !(await this.$refs.confirm.open(
          "Eliminar llamada",
          "¿Seguro que quieres eliminar este contacto?",
          { color: "error", confirmColor: "error" }
        ))
      )
        return;
      axios({
        url: `${process.env.VUE_APP_API_URL}/contactos_estudio/${this.idEstudio}/${idContacto}`,
        method: "DELETE",
      }).then((res) => {
        this.getContactos();
        this.nuevaLlamada = {};
      });
    },
  },
  mounted() {
    this.getContactos();

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    this.$set(
      this.nuevaLlamada,
      "fecha",
      [year, ("00" + (month + 1)).slice(-2), ("00" + day).slice(-2)].join("-")
    );

    const hours = date.getHours();
    const minutes = date.getMinutes();
    this.$set(
      this.nuevaLlamada,
      "hora",
      [("00" + hours).slice(-2), ("00" + minutes).slice(-2)].join(":")
    );
    // this.nuevaLlamada.fecha = ;
  },
};
</script>

<style>
.sticky {
  position: sticky;
  top: 0;
}
</style>
